<template>
    <div id="MessageContent"> 
    <Header/>      
    <body class=" bg-white">
    <!-- header -->
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a @click="onBack" class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" >
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          訊息中心
        </a>             
        <a class="navbar-brand font-weight-bold text-smm me-0 text-right col-4" > 
        </a> 
      </div>
    </nav>   
	
    <!-- End header -->
    <div class="nicolespacetop"></div>
    <!-- End header -->
       <div class="container mb-4">
                <!-- eslint-disable vue/no-v-html -->
        <h5 class="text-simbold my-3  text-s text-black lh18" v-html="mailList.name"></h5>        
        <h5 class=" my-3 my-md-4 text-smm text-dark lh18" v-html="mailList.description"></h5>   
                <!--eslint-enable-->
 
        
        <h5 class=" my-3 my-md-4 text-smm text-muted lh18 text-right"  > {{mailList.insertt}} </h5>
    <!--{{mailList.insertt}}   //moment(String(mailList[0].insertt)).format('Y/m/d H:i')  -->
    </div> 
	
	  <scroll-loader :loader-method="getMessageContent" :loader-disable="disable">
        </scroll-loader> 
        
        <div class="nicolespace"></div>
        <div class="nicolespace"></div>   
    </body>
    <Footer/>
    </div>
</template>


<script>
import Vue from 'vue'
import ScrollLoader from 'vue-scroll-loader' 
Vue.use(ScrollLoader)

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import moment from 'moment';


export default {
    name: 'MessageContent',
    components: {
        Header,
        Footer,
    },
    data () {
    return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        perPage: 10,           //預設10筆
        apiName: "page_message_content",
        pageMode: true,
        currentPage: 0,
        totalPage: 0,

        // data
        mailList: [],
        disable:false,
        isLoadFirst: false,        

            filterData: {}  ,
			
        }
    },
    methods: {
        changeToWebCallback(data){},
        onDetail(mlid){
            this.$JsBridge.callHandler('openWebView', `/message_content?mlid=${mlid}`, this.changeToWebCallback);
        },
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
		onBack(){    
            this.$router.push(
            {
                name : 'MessageList',
            });
        },
		getinsertt(){
		},
        getMessageContent(){
            if(!this.isLoadFirst){
                this.isLoadFirst = true;
            } 
                this.disable = true; 
			
			this.filterData['mlid'] = this.$route.query.mlid; 
            this.apiName = 'page_message_content';
            oAC.getAllRecord(oAC.getApiParam(this),  this.filterData).then(
            res => 
                {
                    this.disable = true;
                    if(res != null && res.table != null && res.table.record.length > 0){
					this.mailList = res.table.record[0];
					this.mailList.insertt = moment(String(this.mailList.insertt)).format('YYYY/MM/DD HH:mm') ;
                    }
                }
            )
            .catch((err)=> {
                this.disable = true;
            this.filterData = {};
                oltConsole.log(err);
            });
			
			 
        },
        checkFirst(){
            if(!this.isLoadFirst){
                this.getMessageContent();
            }
        },
        clearList(){
            this.totalPage = 0;
            this.currentPage = 0;
            this.mailList = [];
        },
    },
    mounted() {
    },
    created(){ 
        if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
        this.clearList();
        setTimeout(this.checkFirst, 300);   
    }
}
</script>